import { gql, useQuery } from "@apollo/client";
import {
  getAllProfitSort,
  getAllProfitSortVariables,
} from "../__generated__/getAllProfitSort";
import { Property } from "../__generated__/globalTypes";

export const GET_ALL_PROFIT_SORT_QUERY = gql`
  query getAllProfitSort($input: GetAllProfitByPropertyInput!) {
    getAllProfitSort(input: $input) {
      ok
      error
      profits {
        _id
        name
        exchange
        ds
        profit
        actualRate
        decreaseRate
        increaseRate
      }
    }
  }
`;

/**
 * DB(profits)에 있는 exchange=(krx,index,amex)에 해당하는
 * Property(actualRate, expectedRate, profit)으로 Sort해서 profit을 가져오는 Hook
 */
export const useGetAllProfitSort = (
  exchange: string | null,
  property: Property
) => {
  exchange = (exchange ?? "krx").replace(/"/g, "");

  return useQuery<getAllProfitSort, getAllProfitSortVariables>(
    GET_ALL_PROFIT_SORT_QUERY,
    {
      variables: {
        input: {
          exchange,
          property: property,
        },
      },
    }
  );
};
