import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  LOCALSTORAGE_EXCHANGE,
  LOCALSTORAGE_TOKEN,
  PROFIT_SORT_BY_WHAT,
} from "./constants";
import { Property } from "./__generated__/globalTypes";

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const isLoggedInVar = makeVar(Boolean(token));
export const authToken = makeVar(token);
export const exchangeVar = makeVar(localStorage.getItem(LOCALSTORAGE_EXCHANGE));
export const profitSortByWhatVar = makeVar(
  localStorage.getItem(PROFIT_SORT_BY_WHAT) ?? Property.actualRate
);

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://stooock-backend.herokuapp.com/graphql"
      : "http://localhost:4000/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-jwt": authToken() || "",
    },
  };
});

const defaultOptions: DefaultOptions = {
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  defaultOptions: defaultOptions,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          token: {
            read() {
              return authToken();
            },
          },
          exchange: {
            read() {
              return exchangeVar();
            },
          },
          profitSortByWhat: {
            read() {
              return profitSortByWhatVar();
            },
          },
        },
      },
    },
  }),
});
