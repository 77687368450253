import React from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps, useParams } from "react-router-dom";
import { IndexChart } from "../components/indexChart";
import { LoadingSpinner } from "../components/loading-spinner";
import { useGetFinanceWithoutVolum } from "../hook/useGetFinanceWithoutVolum";

interface IFinanceParams {
  symbol: string;
}

interface LocationState {
  name: string;
}

/**
 * createAt 2021-10-31
 * updateeAt 2021-11-28
 */
export const SimpleIndexChart = (
  props: RouteComponentProps<{}, any, LocationState | any>
) => {
  const { name } = props.location.state;

  const params = useParams<IFinanceParams>();
  const { loading, data } = useGetFinanceWithoutVolum(params.symbol);

  return (
    <div className="h-screen flex flex-col">
      <Helmet>
        <title>Chart | 테스트</title>
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center">
        {!data?.getFinanceBySymbol.finance || loading ? (
          <div className="w-full flex flex-col items-center justify-center text-purple-500">
            <LoadingSpinner className="h-20 w-20 mt-4" />
            <div className="p-5 rounded-full flex space-x-1">
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-before"></div>
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing"></div>
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-after"></div>
              <h2 className="text-4xl font-extrabold">로딩중</h2>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <p className="mx-auto text-lg font-extrabold">{name}</p>
            <IndexChart finance={data.getFinanceBySymbol.finance} />
          </div>
        )}
      </div>
    </div>
  );
};
