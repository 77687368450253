import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAllProfitByName,
  getAllProfitByNameVariables,
} from "../__generated__/getAllProfitByName";

export const GET_ALL_PROFIT_BY_NAME_QUERY = gql`
  query getAllProfitByName($input: GetAllProfitByNameInput!) {
    getAllProfitByName(input: $input) {
      ok
      error
      profits {
        _id
        name
        exchange
        ds
        profit
        actualRate
        decreaseRate
        increaseRate
      }
    }
  }
`;

/**
 * 이름으로 profit을 가져오는 Hook
 */
export const useGetProfitsByName = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const query = urlParams.get("q");
  const [callQuery, { loading, data }] = useLazyQuery<
    getAllProfitByName,
    getAllProfitByNameVariables
  >(GET_ALL_PROFIT_BY_NAME_QUERY);

  useEffect(() => {
    if (!query) {
      return history.replace("/");
    }
    callQuery({
      variables: {
        input: {
          name: query,
        },
      },
    });
  }, [callQuery, history, location, query]);
  return { loading, data };
};
