import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { exchangeVar } from "../apollo";
import { LOCALSTORAGE_EXCHANGE } from "../constants";
import { useLocalStorage } from "../hook/useLocalStorage";
import { LoginButton } from "./loginButton";
import { SearchBar } from "./search-bar";

export const MyHeader: React.FC = () => {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dropdownOpen, setDropdown] = useState(false);
  const localExchange = exchangeVar() ?? "krx";
  const [exchange, setExchange] = useLocalStorage<string>(
    LOCALSTORAGE_EXCHANGE,
    localExchange
  );

  return (
    <>
      <nav className="bg-purple-500">
        {/* container */}
        <div className="container flex flex-wrap px-4 py-2 mx-auto sm:mx-4 sm:space-x-4">
          {/* brand */}
          <Link
            className="inline-flex p-2 text-white text-xl font-extrabold tracking-wider"
            to="/"
          >
            tttplay.com
          </Link>
          {/* brand */}
          {/* togleer btn */}
          <button
            className="sm:hidden inline-flex items-center justify-center ml-auto
           text-white border h-10 w-10 rounded-md outline-none focus:outline-none"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          {/* togleer btn */}
          {/* menu */}
          <div
            className={`w-full items-center sm:inline-flex sm:w-auto mt-2 sm:mt-0 ${
              navbarOpen ? "flex" : "hidden"
            }`}
          >
            <ul
              className="w-full sm:w-auto
              flex flex-col sm:flex-row
              space-y-2 
             sm:space-y-0 sm:space-x-2"
            >
              {/* dropdown */}
              <li className="relative">
                <button
                  className="flex w-full outline-none focus:outline-none px-4 py-2 rounded-md font-bold bg-purple-900 text-white hover:bg-purple-800"
                  onClick={() => setDropdown(!dropdownOpen)}
                >
                  차트
                </button>
                {/* dropdown menu */}
                <div
                  className={`sm:absolute z-10 bg-white left-0 rounded-md p-2 ${
                    dropdownOpen ? "flex flex-col" : "hidden"
                  }`}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <ul className="space-y-2 sm:w-48">
                    <li>
                      <button
                        onClick={() => {
                          setExchange("krx");
                          if (history.location.pathname === "/")
                            window.location.reload();
                          else {
                            history.replace("/");
                            window.location.reload();
                          }
                        }}
                        className={`flex p-2 font-bold text-gray-600 rounded-md hover:bg-gray-100 hover:text-black ${
                          exchange === "krx" ? "bg-gray-200" : ""
                        }`}
                      >
                        kospi
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          setExchange("index");
                          if (history.location.pathname === "/")
                            window.location.reload();
                          else {
                            history.replace("/");
                            window.location.reload();
                          }
                        }}
                        className={`flex p-2 font-bold text-gray-600 rounded-md hover:bg-gray-100 hover:text-black ${
                          exchange === "index" ? "bg-gray-200" : ""
                        }`}
                      >
                        index
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          setExchange("amex");
                          if (history.location.pathname === "/")
                            window.location.reload();
                          else {
                            history.replace("/");
                            window.location.reload();
                          }
                        }}
                        className={`flex p-2 font-bold text-gray-600 rounded-md hover:bg-gray-100 hover:text-black ${
                          exchange === "amex" ? "bg-gray-200" : ""
                        }`}
                      >
                        amex
                      </button>
                    </li>
                  </ul>
                </div>
                {/* dropdown menu */}
              </li>
              {/* dropdown */}

              <li>
                <SearchBar
                  classNameForm="flex w-full font-bold space-x-2"
                  classNameInput="flex flex-grow outline-none focus:outline-none px-4 py-2 rounded-md"
                  classNamebutton="flex outline-none focus:outline-none px-4 py-2 rounded-md hover:bg-purple-800"
                />
              </li>
              <li>
                <LoginButton className="flex w-full outline-none focus:outline-none justify-center px-4 py-2 rounded-md font-bold bg-yellow-400 text-white hover:bg-yellow-500" />
              </li>
            </ul>
          </div>
          {/* menu */}
        </div>
        {/* container */}
      </nav>
    </>
  );
};
