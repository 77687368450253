import { gql, useMutation } from "@apollo/client";
import { authToken, isLoggedInVar } from "../apollo";
import {
  KakaoError,
  LoginResponse,
  UserProfile,
} from "../components/kakaoLogin/types";
import { LOCALSTORAGE_TOKEN } from "../constants";
import {
  loginAndCreate,
  loginAndCreateVariables,
} from "../__generated__/loginAndCreate";

export const LOGIN_MUTATION = gql`
  mutation loginAndCreate($input: LoginAndCreateInput!) {
    loginAndCreate(input: $input) {
      ok
      error
      token
    }
  }
`;

export const useLoginMutation = () => {
  const token = "e4f03318770cfdce33f3eba546e288e3";

  const onCompleted = (data: loginAndCreate) => {
    const {
      loginAndCreate: { ok, token, error },
    } = data;
    if (ok && token) {
      localStorage.setItem(LOCALSTORAGE_TOKEN, token);
      authToken(token);
      isLoggedInVar(true);
    } else {
      alert(error);
    }
  };

  const [loginMutation, { data, loading, error }] = useMutation<
    loginAndCreate,
    loginAndCreateVariables
  >(LOGIN_MUTATION, {
    onCompleted,
  });

  const responseOnSuccess = (response: {
    response: LoginResponse;
    profile?: UserProfile;
  }) => {
    if (response.profile) {
      loginMutation({
        variables: {
          input: {
            id: String(response.profile?.id),
            nickname: response.profile?.kakao_account.profile.nickname,
          },
        },
      });
    }
  };

  const responseFail = (error: KakaoError) => {
    alert(error);
  };

  return {
    token,
    responseOnSuccess,
    responseFail,
    data,
    loading,
    error,
  };
};
