import { gql, useQuery } from "@apollo/client";
import {
  getFinanceBySymbol,
  getFinanceBySymbolVariables,
} from "../__generated__/getFinanceBySymbol";

const GET_FINANCE_BY_SYMBOL_QUERY = gql`
  query getFinanceBySymbol($input: GetFinanceInput!) {
    getFinanceBySymbol(input: $input) {
      ok
      finance {
        _id
        exchange
        ds
        df {
          ds
          Open
          High
          Low
          y
          Volume
          MA5
          MA20
        }
        forecasts {
          ds
          forecast {
            ds
            yhat
            yhat_lower
            yhat_upper
            yhat_diff
            MA5
            MA20
          }
        }
      }
    }
  }
`;

export const useGetFinance = (exchange: string, symbol: string) => {
  symbol = symbol.replace("-", "/");
  const { loading, data } = useQuery<
    getFinanceBySymbol,
    getFinanceBySymbolVariables
  >(GET_FINANCE_BY_SYMBOL_QUERY, {
    variables: {
      input: {
        symbol,
      },
    },
  });
  // let finances = data?.getFinanceBySymbol.finances ?? [];
  return {
    loading,
    data,
  };
};
