import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../components/loading-spinner";
import { useGetProfitsByName } from "../hook/useGetAllProfitByName";

/**
 * createAt 2021-10-31
 * updateeAt 2021-11-23
 */
export const SearchPage = () => {
  const { loading, data: Profits } = useGetProfitsByName();
  return (
    <div className="min-h-screen flex justify-center bg-purpleGray-light text-purpleGray-text border-purpleGray-text">
      <Helmet>
        <title>Main | 테스트</title>
      </Helmet>
      <div className="max-w-7xl flex flex-col mx-auto">
        {/* 언젠가 여기에 라디오 버튼이 들어가야함 */}

        <div className="w-full flex flex-col items-center mt-0 sm:mt-2 bg-purpleGray-dark">
          {!Profits || loading ? (
            <div className="w-full flex flex-col items-center justify-center text-purple-500">
              <LoadingSpinner className="h-20 w-20 mt-4" />
              <div className="p-5 rounded-full flex space-x-1">
                <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-before"></div>
                <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing"></div>
                <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-after"></div>
                <h2 className="text-4xl font-extrabold">로딩중</h2>
              </div>
            </div>
          ) : (
            <div className="w-full grid text-gray-300">
              <div className="w-full grid border">
                <div className="grid grid-cols-2 gap-1 items-center justify-center text-lg font-extrabold">
                  <div className="flex flex-col p-1 border-r ml-2 tracking-wider space-y-1">
                    <span className="space-y-1 border-b">종목코드</span>
                    <span className="space-y-1">종목명</span>
                  </div>
                  <div className="flex flex-col p-1 border-r items-center justify-center tracking-wider">
                    <span className="border-b border-gray-200">이율</span>
                    <div className="flex flex-row p-1 items-center justify-center tracking-wider text-xs truncate">
                      <span className="">할인율</span>
                      <span className="">+하락률</span>
                      <span className="">+상승률</span>
                    </div>
                  </div>
                </div>
              </div>
              {Profits?.getAllProfitByName.profits?.map((profit) => (
                <div key={profit._id} className="w-full grid mt-1">
                  <Link
                    key={profit._id}
                    className="gap-1 items-center text-lg font-extrabold truncate"
                    to={{
                      pathname: `/profit/${
                        profit.exchange
                      }/${profit._id.replace("/", "-")}`,
                      state: { name: profit.name },
                    }}
                  >
                    <div className="grid grid-cols-2 p-1 border-r border-b">
                      <div className="flex flex-col p-1 border-r border-gray-200 ml-2  tracking-wider space-y-1 ">
                        <span className="border-b border-gray-200 ">
                          {profit._id}
                        </span>
                        <span className="truncate">{profit.name}</span>
                      </div>
                      <div className="flex flex-col p-1  items-center justify-center border-gray-200 tracking-wider text-lg truncate">
                        {profit.profit >= 0 ? (
                          <span className="truncate text-red-600">
                            {Math.round(profit.profit * 100) / 100}%
                          </span>
                        ) : (
                          <span className="truncate text-blue-600">
                            {Math.round(profit.profit * 100) / 100}%
                          </span>
                        )}
                        <div className="flex flex-row p-1 border-t items-center justify-center text-xs truncate">
                          {profit.actualRate >= 0 ? (
                            <span className="truncate text-red-600">
                              +{Math.round(profit.actualRate * 100) / 100}%
                            </span>
                          ) : (
                            <span className="truncate text-blue-600">
                              {Math.round(profit.actualRate * 100) / 100}%
                            </span>
                          )}
                          {profit.decreaseRate >= 0 ? (
                            <span className="truncate text-red-600">
                              +{Math.round(profit.decreaseRate * 100) / 100}%
                            </span>
                          ) : (
                            <span className="truncate text-blue-600">
                              {Math.round(profit.decreaseRate * 100) / 100}%
                            </span>
                          )}
                          {profit.increaseRate >= 0 ? (
                            <span className="truncate text-red-600">
                              +{Math.round(profit.increaseRate * 100) / 100}%
                            </span>
                          ) : (
                            <span className="truncate text-blue-600">
                              {Math.round(profit.increaseRate * 100) / 100}%
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
