import React from "react";

interface ILoadingSpinnerProps {
  className?: string;
}

/**
 * cx, cy는 원점위치
 * 원둘레 2*R*Pi 3.14*2*10=62.8
 */
export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  className,
}) => {
  return (
    <svg
      className={`animate-spin ${className ? className : "h-10 w-10 m-2"}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <circle
        className="opacity-75"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        strokeDasharray="20 42.8"
      ></circle>
    </svg>
  );
};
