import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotFound } from "../page/404";
import { Login } from "../page/login";
import React from "react";

interface IRoute {
  path: string;
  component: () => JSX.Element;
}

const commonRoutes: IRoute[] = [
  {
    path: "/",
    component: Login,
  },
];

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Switch>
        {commonRoutes.map((route) => (
          <Route
            key={"common" + route.path}
            path={route.path}
            exact
            component={route.component}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
