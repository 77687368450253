import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MyHeader } from "../components/myHeader";
import { NotFound } from "../page/404";
import { Profit } from "../page/profit";
import { SearchPage } from "../page/search-page";
import { SimpleChart } from "../page/simple-chart";
import { SimpleIndexChart } from "../page/SimpleIndexChart";

interface IRoute {
  path: string;
  component: (any: any) => JSX.Element;
}

const commonRoutes: IRoute[] = [
  {
    path: "/",
    component: Profit,
  },
  {
    path: "/profit/index/:symbol",
    component: SimpleIndexChart,
  },
  {
    path: "/profit/:exchange/:symbol",
    component: SimpleChart,
  },
  {
    path: "/search",
    component: SearchPage,
  },
];

export const LoggedInRouter = () => {
  return (
    <Router>
      <MyHeader />
      <Switch>
        {commonRoutes.map((route) => (
          <Route
            key={"common" + route.path}
            path={route.path}
            exact
            component={route.component}
          />
        ))}
        <Route key="NotFound" component={NotFound} />
      </Switch>
    </Router>
  );
};
