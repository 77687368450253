import React from "react";
import { Helmet } from "react-helmet-async";
import { LoadingSpinner } from "../components/loading-spinner";
import { MainKakaoLoginButton } from "../components/mainLoginButton";
import { useLoginMutation } from "../hook/useLoginMutation";

export const Login = () => {
  const { token, responseOnSuccess, responseFail, data, loading } =
    useLoginMutation();

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-900 overflow-hidden">
      <Helmet>
        <title>Home | Login</title>
      </Helmet>
      {/* grid background */}
      <div className="absolute inset-0 p-2 grid grid-cols-12 gap-2 transform -skew-y-12 scale-125">
        {/* row 1 */}
        <div className="col-span-2 bg-gray-800 rounded animate-pulse4"></div>
        <div className="col-span-4 bg-gray-800 rounded animate-pulse2"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse3"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse3"></div>
        {/* row 2 */}
        <div className="col-span-5 bg-gray-800 rounded animate-pulse6"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse2"></div>
        <div className="col-span-2 bg-gray-800 rounded animate-pulse4"></div>
        <div className="col-span-2 bg-gray-800 rounded animate-pulse5"></div>
        {/* row 3 */}
        <div className="col-span-2 bg-gray-800 rounded animate-pulse2"></div>
        <div className="col-span-5 bg-gray-800 rounded animate-pulse5"></div>
        <div className="col-span-2 bg-gray-800 rounded animate-pulse4"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse3"></div>
        {/* row 4 */}
        <div className="col-span-3 bg-gray-800 rounded animate-pulse4"></div>
        <div className="col-span-5 bg-gray-800 rounded animate-pulse3"></div>
        <div className="col-span-4 bg-gray-800 rounded animate-pulse6"></div>
        {/* row 5 */}
        <div className="col-span-2 bg-gray-800 rounded animate-pulse6"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse3"></div>
        <div className="col-span-4 bg-gray-800 rounded animate-pulse2"></div>
        <div className="col-span-3 bg-gray-800 rounded animate-pulse5"></div>
        {/* row 6 */}
        <div className="col-span-4 bg-gray-800 rounded animate-pulse4"></div>
        <div className="col-span-4 bg-gray-800 rounded animate-pulse6"></div>
        <div className="col-span-2 bg-gray-800 rounded animate-pulse3"></div>
        <div className="col-span-2 bg-gray-800 rounded animate-pulse2"></div>
      </div>

      {/* content */}
      <div className="absolute w-64 items-center justify-center">
        <h2 className="text-4xl text-white font-extrabold">
          Wellcome <span className="text-purple-500">tttplay.com</span>
          <p>작동 테스트중</p>
        </h2>
        {/* card */}
        {!data || loading ? (
          <div className="relative mt-10 h-full w-full rounded-xl">
            <div className="absolute -right-4 -bottom-4 border-yellow-400 border-8 h-full w-full rounded-xl"></div>
            <div className="relative flex items-center justify-center bg-yellow-400 hover:bg-yellow-500 rounded-xl px-4 py-4 ">
              <MainKakaoLoginButton
                className="w-full flex items-center justify-center text-4xl text-white font-extrabold"
                token={token}
                onSuccess={responseOnSuccess}
                onFail={responseFail}
              />
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-center text-white">
            <LoadingSpinner className="h-20 w-20 mt-4" />
            <div className="p-5 rounded-full flex space-x-1">
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-before"></div>
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing"></div>
              <div className="w-5 h-5 mt-5 bg-white rounded-full animate-dot-flashing-after"></div>
              <h2 className="text-4xl font-extrabold">로딩중</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
