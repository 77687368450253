import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

interface SearchBarProps {
  classNameForm?: string;
  classNameInput?: string;
  classNamebutton?: string;
}

interface SearchBarFormProps {
  search: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  classNameForm,
  classNameInput,
  classNamebutton,
}) => {
  const history = useHistory();

  const onSearchSubmit = () => {
    const { search } = getValues();
    history.push({
      pathname: "/search",
      search: `?q=${search}`,
    });
  };

  const { register, handleSubmit, getValues } = useForm<SearchBarFormProps>();

  return (
    <form
      onSubmit={handleSubmit(onSearchSubmit)}
      className={`${
        classNameForm ? classNameForm : "flex justify-center items-center"
      }`}
    >
      <input
        {...register("search", {
          required: true,
        })}
        className={`${
          classNameInput ? classNameInput : "px-1 py-1 md:px-6 md:py-2 border-2"
        }`}
        type="search"
        placeholder="회사명을 입력하세요..."
      />
      <button
        className={`${classNamebutton ? classNamebutton : "p-1"}`}
        onSubmit={handleSubmit(onSearchSubmit)}
      >
        <span role="img" aria-label="search">
          🔍
        </span>
      </button>
    </form>
  );
};
