import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { isLoggedInVar } from "../apollo";
import { LOCALSTORAGE_TOKEN } from "../constants";
import {
  ExtendedWindow,
  KakaoError,
  LoginResponse,
  UserProfile,
} from "./kakaoLogin/types";

declare let window: ExtendedWindow;

const loadSdk = () => {
  return new Promise((resolve, reject) => {
    const js: HTMLScriptElement = document.createElement("script");

    js.id = "kakao-sdk";
    js.src = "//developers.kakao.com/sdk/js/kakao.min.js";
    js.onload = resolve;

    document.body.append(js);
  });
};

interface IKakaoLoginProps {
  className?: string;
  onSuccess: (response: {
    response: LoginResponse;
    profile?: UserProfile;
  }) => void;
  onFail: (error: KakaoError) => void;
  token: string;
}

export const MainKakaoLoginButton: React.FC<IKakaoLoginProps> = ({
  className,
  onSuccess,
  onFail,
  token,
}) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  useEffect(() => {
    const loadKakaoSdk = async () => {
      await loadSdk();
      window.Kakao.init(token);
    };
    loadKakaoSdk();
  }, [token]);

  const onButtonClick = () => {
    window.Kakao?.Auth.login({
      success: (response) => {
        window.Kakao?.API.request({
          url: "/v2/user/me",
          success: (profile) => {
            const result = { response, profile };
            onSuccess(result);
          },
          fail: onFail,
        });
      },
      fail: onFail,
    });
  };

  const onLogout = () => {
    window.Kakao?.Auth.logout(() => {
      localStorage.removeItem(LOCALSTORAGE_TOKEN);
      isLoggedInVar(false);
    });
  };

  const onClick = isLoggedIn ? onLogout : onButtonClick;
  const children = isLoggedIn ? "로그아웃" : "로그인";
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
      <svg
        className="w-10 h-10 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 8l4 4m0 0l-4 4m4-4H3"
        />
      </svg>
    </button>
  );
};
