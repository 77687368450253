import { SliderStep } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";
import { getFinanceBySymbol_getFinanceBySymbol_finance } from "../__generated__/getFinanceBySymbol";

interface MyChartProps {
  className?: string;
  finance: getFinanceBySymbol_getFinanceBySymbol_finance;
}

export const MyChart: React.FC<MyChartProps> = ({ className, finance }) => {
  return (
    <Plot
      className={`${className ? className : ""}`}
      data={[
        {
          name: "하한예측",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast
            .yhat_lower,
          type: "scatter",
          line: { color: "blue", width: 2 },
          showlegend: false,
          hovertemplate: "%{y:.2f}",
        },
        {
          name: "예측값",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast.yhat,
          type: "scatter",
          line: { color: "gray", width: 2 },
          showlegend: false,
          hovertemplate: "%{y:.2f}",
        },
        {
          name: "상한",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast
            .yhat_upper,
          type: "scatter",
          line: { color: "red", width: 2 },
          showlegend: false,
          hovertemplate: "%{y:.2f}",
        },
        {
          name: "가격",
          x: finance.df.ds,
          open: finance.df.Open,
          high: finance.df.High,
          low: finance.df.Low,
          close: finance.df.y,
          increasing: { line: { color: "rgb(238,55,57)", width: 3 } },
          decreasing: { line: { color: "rgb(10,125,242)", width: 3 } },
          type: "candlestick",
          showlegend: false,
        },
        {
          name: "5일",
          x: finance.df.ds,
          y: finance.df.MA5,
          type: "scatter",
          line: { color: "rgb(86,217,118)", width: 2 },
          showlegend: false,
          hovertemplate: "%{y:.2f}",
        },
        {
          name: "20일",
          x: finance.df.ds,
          y: finance.df.MA20,
          type: "scatter",
          line: { color: "rgb(229,82,78)", width: 2 },
          showlegend: false,
          hovertemplate: "%{y:.2f}",
        },
        {
          name: "diff",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast.yhat_diff,
          type: "scatter",
          line: { color: "gray", width: 1 },
          opacity: 0.5,
          yaxis: "y2",
          showlegend: false,
        },
        {
          name: "MA5",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast.MA5,
          type: "scatter",
          line: { color: "rgb(191,131,245)", width: 2 },
          yaxis: "y2",
          showlegend: false,
        },
        {
          name: "MA20",
          x: finance.forecasts[finance.forecasts.length - 1].forecast.ds,
          y: finance.forecasts[finance.forecasts.length - 1].forecast.MA20,
          type: "scatter",
          line: { color: "rgb(241,172,97)", width: 2 },
          yaxis: "y2",
          showlegend: false,
        },
        {
          name: "거래량",
          x: finance.df.ds,
          y: finance.df.Volume,
          type: "bar",
          marker: {
            opacity: 0.5,
            color: finance.df.Open.map((valueOpen, index) =>
              valueOpen - finance.df.y[index] >= 0
                ? "rgb(10,125,242)"
                : "rgb(238,55,57)"
            ),
          },
          yaxis: "y3",
          showlegend: false,
          hovertemplate: "%{y:f}",
        },
      ]}
      layout={{
        xaxis: {
          fixedrange: true,
          range: [
            finance.forecasts[finance.forecasts.length - 1].forecast.ds[
              finance.forecasts[finance.forecasts.length - 1].forecast.ds
                .length - 60
            ],
            finance.forecasts[finance.forecasts.length - 1].forecast.ds[
              finance.forecasts[finance.forecasts.length - 1].forecast.ds
                .length - 1
            ],
          ],
          tickformat: "%m.%d",
          rangebreaks: [{ bounds: ["sat", "mon"] }],
          rangeselector: {
            x: 0,
            y: 1.1,
            xanchor: "left",
            font: { size: 8 },
            buttons: [
              {
                step: "month",
                stepmode: "backward",
                count: 2,
                label: "2 month",
              },
              {
                step: "month",
                stepmode: "backward",
                count: 3,
                label: "3 month",
              },
              {
                step: "month",
                stepmode: "backward",
                count: 4,
                label: "4 month",
              },
              {
                step: "month",
                stepmode: "backward",
                count: 5,
                label: "5 month",
              },
              {
                step: "month",
                stepmode: "backward",
                count: 6,
                label: "6 months",
              },
              {
                step: "all",
                label: "All dates",
              },
            ],
          },
        },
        xaxis2: { matches: "x", showticklabels: false, anchor: "y3" },
        yaxis: {
          anchor: "x",
          domain: [0.2, 1],
          fixedrange: true,
          autorange: true,
        },
        yaxis2: {
          anchor: "x",
          domain: [0.2, 0.1],
          fixedrange: true,
          autorange: true,
          overlaying: "y",
          side: "right",
        },
        yaxis3: {
          anchor: "x2",
          autorange: true,
          domain: [0.0, 0.2],
          fixedrange: true,
        },
        width: window.screen.width,
        height: window.screen.height,
        // margin: { l: 40, r: 40, t: 20, b: 20 },
        boxgap: 0,
        hovermode: "x",
        updatemenus: [
          {
            type: "buttons",
            buttons: [
              {
                label: "Play",
                method: "animate",
                args: [
                  null,
                  {
                    frame: { duration: 1000, redraw: true },
                    fromcurrent: true,
                    transition: {
                      duration: 0,
                      easing: "quadratic-in-out",
                    },
                  },
                ],
              },
              {
                label: "Pause",
                method: "animate",
                args: [
                  [null],
                  {
                    frame: { duration: 0, redraw: false },
                    mode: "immediate",
                    transition: { duration: 0 },
                  },
                ],
              },
            ],
            showactive: false,
            direction: "right",
            // pad: { r: 0, l: 100, t: 0 },
            x: 0.25,
            y: 1.07,
          },
        ],
        sliders: [
          {
            active: finance.forecasts.length - 1,
            currentvalue: {
              font: { size: 16 },
              prefix: "예측일: ",
              visible: true,
              xanchor: "right",
            },
            transition: { duration: 0, easing: "cubic-in-out" },

            // pad: { r: 0, l: 0, b: 0, t: 0 },
            len: 0.7,
            x: 0.3,
            y: 1.12,
            font: { family: "sans-serif", size: 8, color: "bloack" },
            steps: [
              ...finance.forecasts.map((forecast) => {
                const step: Partial<SliderStep> = {
                  args: [
                    [forecast.ds],
                    {
                      frame: { duration: 0, redraw: true },
                      mode: "immediate",
                      transition: { duration: 0 },
                    },
                  ],
                  label: forecast.ds,
                  method: "animate",
                };
                return step;
              }),
            ],
          },
        ],
      }}
      frames={[
        ...finance.forecasts.map((forecast, index) => {
          const dfRange =
            finance.df.ds.length - finance.forecasts.length + index + 1;
          const frame: Plotly.Frame = {
            group: "",
            name: `${forecast.ds}`,
            traces: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            baseframe: "",
            data: [
              {
                name: "하한",
                x: forecast.forecast.ds,
                y: forecast.forecast.yhat_lower,
                type: "scatter",
                line: { color: "blue", width: 2 },
                showlegend: false,
                hovertemplate: "%{y:.2f}",
              },
              {
                name: "예측치",
                x: forecast.forecast.ds,
                y: forecast.forecast.yhat,
                type: "scatter",
                line: { color: "gray", width: 2 },
                showlegend: false,
                hovertemplate: "%{y:.2f}",
              },
              {
                name: "상한",
                x: forecast.forecast.ds,
                y: forecast.forecast.yhat_upper,
                type: "scatter",
                line: { color: "red", width: 2 },
                showlegend: false,
                hovertemplate: "%{y:.2f}",
              },
              {
                name: "가격",
                x: finance.df.ds.slice(0, dfRange),
                open: finance.df.Open.slice(0, dfRange),
                high: finance.df.High.slice(0, dfRange),
                low: finance.df.Low.slice(0, dfRange),
                close: finance.df.y.slice(0, dfRange),
                increasing: { line: { color: "rgb(238,55,57)", width: 3 } },
                decreasing: { line: { color: "rgb(10,125,242)", width: 3 } },
                type: "candlestick",
                showlegend: false,
                hovertemplate: "%{open:.2f}",
              },
              {
                name: "5일",
                x: finance.df.ds.slice(0, dfRange),
                y: finance.df.MA5.slice(0, dfRange),
                type: "scatter",
                line: { color: "rgb(86,217,118)", width: 2 },
                showlegend: false,
                hovertemplate: "%{y:.2f}",
              },
              {
                name: "20일",
                x: finance.df.ds.slice(0, dfRange),
                y: finance.df.MA20.slice(0, dfRange),
                type: "scatter",
                line: { color: "rgb(229,82,78)", width: 2 },
                showlegend: false,
                hovertemplate: "%{y:.2f}",
              },
              {
                name: "diff",
                x: forecast.forecast.ds,
                y: forecast.forecast.yhat_diff,
                type: "scatter",
                line: { color: "gray", width: 1 },
                opacity: 0.5,
                yaxis: "y2",
                showlegend: false,
              },
              {
                name: "MA5",
                x: forecast.forecast.ds,
                y: forecast.forecast.MA5,
                type: "scatter",
                line: { color: "rgb(191,131,245)", width: 2 },
                yaxis: "y2",
                showlegend: false,
              },
              {
                name: "MA20",
                x: forecast.forecast.ds,
                y: forecast.forecast.MA20,
                type: "scatter",
                line: { color: "rgb(241,172,97)", width: 2 },
                yaxis: "y2",
                showlegend: false,
              },
              {
                name: "거래량",
                x: finance.df.ds.slice(0, dfRange),
                y: finance.df.Volume.slice(0, dfRange),
                type: "bar",
                marker: {
                  opacity: 0.5,
                  color: finance.df.Open.map((valueOpen, index) =>
                    valueOpen - finance.df.y[index] >= 0
                      ? "rgb(10,125,242)"
                      : "rgb(238,55,57)"
                  ),
                },
                yaxis: "y3",
                showlegend: false,
                hovertemplate: "%{y:f}",
              },
            ],
            layout: {},
          };
          return frame;
        }),
      ]}
      config={{
        modeBarButtonsToRemove: ["select2d", "lasso2d"],
        displayModeBar: true,
        displaylogo: false,
      }}
    />
  );
};
