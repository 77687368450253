import { useMutation } from "@apollo/client";
import React from "react";
import { authToken, isLoggedInVar } from "../apollo";
import { KakaoLogin } from "../components/kakaoLogin/kakaoLogin";
import {
  KakaoError,
  LoginResponse,
  UserProfile,
} from "../components/kakaoLogin/types";
import { LOCALSTORAGE_TOKEN } from "../constants";
import { LOGIN_MUTATION } from "../hook/useLoginMutation";
import {
  loginAndCreate,
  loginAndCreateVariables,
} from "../__generated__/loginAndCreate";

interface ILoginButtonProps {
  className?: string;
}

export const LoginButton: React.FC<ILoginButtonProps> = ({ className }) => {
  const token = "e4f03318770cfdce33f3eba546e288e3";

  const onCompleted = (data: loginAndCreate) => {
    const {
      loginAndCreate: { ok, token, error },
    } = data;
    if (ok && token) {
      localStorage.setItem(LOCALSTORAGE_TOKEN, token);
      authToken(token);
      isLoggedInVar(true);
    } else {
      alert(error);
    }
  };

  const [loginMutation] = useMutation<loginAndCreate, loginAndCreateVariables>(
    LOGIN_MUTATION,
    {
      onCompleted,
    }
  );

  const responseOnSuccess = (response: {
    response: LoginResponse;
    profile?: UserProfile;
  }) => {
    if (response.profile) {
      loginMutation({
        variables: {
          input: {
            id: String(response.profile?.id),
            nickname: response.profile?.kakao_account.profile.nickname,
          },
        },
      });
    }
  };

  const responseFail = (error: KakaoError) => {
    alert(error);
  };

  return (
    <KakaoLogin
      className={`${className ? className : "bg-yellow-300 p-1 md:p-4"}`}
      token={token}
      onSuccess={responseOnSuccess}
      onFail={responseFail}
    />
  );
};
