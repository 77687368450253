import { gql, useQuery } from "@apollo/client";
import {
  getFinanceBySymbolWithoutVolume,
  getFinanceBySymbolWithoutVolumeVariables,
} from "../__generated__/getFinanceBySymbolWithoutVolume";

const GET_FINANCE_BY_SYMBOL_WITHOUT_VOLUME_QUERY = gql`
  query getFinanceBySymbolWithoutVolume($input: GetFinanceInput!) {
    getFinanceBySymbol(input: $input) {
      ok
      finance {
        _id
        exchange
        ds
        df {
          ds
          Open
          High
          Low
          y
          MA5
          MA20
        }
        forecasts {
          ds
          forecast {
            ds
            yhat
            yhat_lower
            yhat_upper
            yhat_diff
            MA5
            MA20
          }
        }
      }
    }
  }
`;

export const useGetFinanceWithoutVolum = (symbol: string) => {
  symbol = symbol.replace("-", "/");
  const { loading, data } = useQuery<
    getFinanceBySymbolWithoutVolume,
    getFinanceBySymbolWithoutVolumeVariables
  >(GET_FINANCE_BY_SYMBOL_WITHOUT_VOLUME_QUERY, {
    variables: {
      input: {
        symbol,
      },
    },
  });

  return {
    loading,
    data,
  };
};
