/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Property {
  actualRate = "actualRate",
  expectedRate = "expectedRate",
  profit = "profit",
}

export interface GetAllProfitByNameInput {
  name: string;
}

export interface GetAllProfitByPropertyInput {
  exchange: string;
  property: Property;
}

export interface GetFinanceInput {
  symbol: string;
}

export interface LoginAndCreateInput {
  id: string;
  nickname: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
